body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: rgba(0, 0, 0, .9) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jVeafb {
  min-height: 30px !important;
}

.main {
  background: '#ccc';
}

.form-control-sm {
  min-height: 38px !important;
}

.dims-editor {
  border: 2px solid #eee !important;
  padding: 3px;
}

#sidebar {
  background: #7386D5;
  color: #fff;
  height: 90vh;
  transition: all 0.3s;
}
.z-0 div {
  z-index: 0  !important;
} 
.logout {
  flex-direction: row-reverse;
}

.footer {
  display: inline;
}

/* #sidebar ul li a {
  font-size: 14px;
} */
@media (max-width: 768px) {
  #sidebar {
    height: inherit;
  }

  .footer {
    display: none;
  }

  .logout {
    flex-direction: row;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #sidebarCollapse span {
    display: none;
  }
}