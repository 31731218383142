 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
body {
  background: #eff3f5;
  padding: 0px !important;
  margin: 0px !important;
}
.main {
  color: rgba(18, 21, 78, 0.7) !important;
  background: #eff3f5;
  height: 90vh;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color:rgba(0,0,0,.9) !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: rgba(0, 0, 0, .9) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jVeafb {
  min-height: 30px !important;
}

.main {
  background: '#ccc';
}

.form-control-sm {
  min-height: 38px !important;
}

.dims-editor {
  border: 2px solid #eee !important;
  padding: 3px;
}

#sidebar {
  background: #7386D5;
  color: #fff;
  height: 90vh;
  transition: all 0.3s;
}
.z-0 div {
  z-index: 0  !important;
} 
.logout {
  flex-direction: row-reverse;
}

.footer {
  display: inline;
}

/* #sidebar ul li a {
  font-size: 14px;
} */
@media (max-width: 768px) {
  #sidebar {
    height: inherit;
  }

  .footer {
    display: none;
  }

  .logout {
    flex-direction: row;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #sidebarCollapse span {
    display: none;
  }
}
